<template>
  <ContainerBasic container-type="FULL_WIDTH" :style="blockData.bgColor">
    <ContainerBasic
      container-type="PAGE"
      class="px-sm"
      :class="{ 'pt-xl pb-xl': blockData.bgColor.length > 0 }"
    >
      <Headline
        v-if="blockData.headline"
        :headline="blockData.headline"
        :level="'h2'"
        :has-decoration="true"
        :style="blockData.textColor"
      />
      <div
        class="grid grid-flow-row mt-md gap-sm md:gap-md"
        :class="tileWrapperClasses"
      >
        <div
          v-for="(item, index) of blockData.items"
          :key="item.id"
          class="bg-vdv-bg-full"
          :class="getTileClasses(index)"
        >
          <ImageBasic
            v-if="item.image"
            :image-data="item.image"
            class="object-cover w-full aspect-video"
          />

          <div class="p-sm md:p-md">
            <div class="">
              <Headline
                v-if="item.headline"
                :headline="item.headline"
                :level="'h4'"
                align-text="left"
              />
              <WYSIWYGContentRaw
                :content="item.text"
                inner-classes="px-0"
                class="mt-sm"
                :no-container-padding="true"
              ></WYSIWYGContentRaw>
            </div>
          </div>
        </div>
      </div>
    </ContainerBasic>
  </ContainerBasic>
</template>
<script lang="ts" setup>
import ContainerBasic from '@/components/components/container-basic.vue';
import { Headline, WYSIWYGContentRaw } from '~/complib';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import type { TextImageTilesData } from '../blockType';

const props = defineProps({
  classes: {
    type: String,
    required: false,
    default: '',
  },
  blockData: {
    type: Object as PropType<TextImageTilesData>,
    required: true,
    default: {} as TextImageTilesData,
  },
});

const tileWrapperClasses = computed(() => {
  if (props.blockData.items.length > 4) {
    return 'md:grid-cols-6';
  }

  if (props.blockData.items.length === 4) {
    return 'desktop:md:grid-cols-2 touch:md:grid-cols-4 desktop:lg:grid-cols-4 ';
  }

  return `md:grid-cols-${props.blockData.items.length}`;
});

function getTileClasses(index: number) {
  if (props.blockData.items.length < 5) {
    return '';
  } else if (props.blockData.items.length === 5) {
    if (index < 2) {
      return 'md:col-span-3';
    }

    return 'md:col-span-2';
  } else if (props.blockData.items.length > 5) {
    return 'md:col-span-2';
  }
}
</script>
<style scoped lang="postcss"></style>
